<template>
  <section class="main-wrap">
    <header class="inner-page-header">
      <h1 class="big-title" v-if="!campaign.name">Create a project</h1>
      <h1 class="big-title" v-else>Edit your project</h1>
    </header>

    <form v-if="platforms && platforms.length" action="#" class="create-campaign-form" @submit="createCampaign">
      <div class="input-label-wrap" :class="{ filled: campaign.name }">
        <input v-model="campaign.name" type="text" class="label-focus" required />
        <p class="label">Project name</p>
      </div>

      <div class="input-label-wrap" :class="{ filled: campaign.mediaplan }">
        <input v-model="campaign.mediaplan" type="text" class="label-focus" />
        <p class="label">Media Plan Link (optional)</p>
      </div>

      <div class="checkbox-wrap">
        <div
          v-for="(platform, index) in platforms.filter((i) => i.name === 'Facebook / Instagram' || i.name === 'Google Ads')"
          :key="'active-' + index"
          class="checkbox"
          :class="{ active: campaign.platforms.includes(platform.id) }"
          @click="tooglePlatform(platform.id)"
        >
          <div class="wrap">
            <img alt="Facebook" :src="platform.icon" />
            <p>{{ platform.name }}</p>
            <div class="check">
              <img alt="Check" src="@/assets/img/check-icon.svg" />
            </div>
          </div>
        </div>
      </div>

      <button type="submit" class="bt" :class="{ 'is-loading': isSaving }">
        <div class="bt-text">
          <p v-if="!campaign.name">Next</p>
          <p v-else>Save</p>
          <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
        </div>
        <p class="loader">
          &nbsp;
          <svg class="main-loader" width="38" height="38" xmlns="http://www.w3.org/2000/svg" stroke="#EFF1F2">
            <g transform="translate(1 1)" stroke-width="2" fill="none" fill-rule="evenodd">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
              </path>
            </g>
          </svg>
          &nbsp;
        </p>
      </button>
    </form>

    <div class="other-platform" v-if="platforms && platforms.length">
      <p>Working on</p>
      <div v-for="(platform, index) in platforms.filter((i) => i.name !== 'Facebook / Instagram' && i.name !== 'Google Ads')" :key="'pending-' + index">
        <img :alt="platform.name" :src="platform.icon" />
      </div>
    </div>

    <div class="small-outline-bt dark" @click="browserBack">
      <img alt="Plus" class="bt-img" src="@/assets/img/arrow-right.svg" />
      <p>Back</p>
    </div>
  </section>
</template>

<script>
import { isNil } from 'lodash'
import { mapState, mapActions } from 'vuex'
import AccountClientCampaignsDB from '@/firebase/account-client-campaigns-db'
import { doAlert } from '@/misc/helpers'

export default {
  head: function () {
    return {
      title: {
        inner: 'Create a project',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} Create a project for your campagins`,
          id: 'desc',
        },
      ],
    }
  },

  data() {
    return {
      campaign: {
        name: '',
        platforms: [],
        isArchived: false,
      },
      isSaving: false,
      mode: 'create',
    }
  },

  computed: {
    clientId() {
      return this.$route.params.clientId
    },
    campaignId() {
      return this.$route.params.campaignId
    },
    ...mapState('app', ['appTitle', 'platforms']),
    ...mapState('users', ['account', 'accountMembership', 'selectedClient']),
  },

  watch: {
    account: {
      async handler(account) {
        if (!isNil(account)) {
          // console.log('account', 'changed')

          this.selectClient(this.$route.params.clientId)

          if (this.clientId && this.campaignId) {
            this.mode = 'update'

            const accountClientCampaignsDbRef = new AccountClientCampaignsDB(this.account[0].id, this.clientId)
            this.campaign = await accountClientCampaignsDbRef.read(this.campaignId)
          }
        }
      },
      immediate: true,
    },
    mode: {
      async handler(mode) {
        if (!isNil(mode) && mode !== 'update') {
          if (this.selectedClient.nbCampaigns >= this.accountMembership.config.campaigns) {
            this.$router.push({ name: 'CampaignsList', query: { membershipGuard: true } })
            return
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    tooglePlatform(platform) {
      if (this.campaign.platforms.includes(platform)) {
        const index = this.campaign.platforms.indexOf(platform)
        this.campaign.platforms.splice(index, 1)
      } else {
        this.campaign.platforms.push(platform)
      }
    },
    async createCampaign(e) {
      e.preventDefault()

      this.isSaving = true

      let dbCampaign = this.campaign

      if (!dbCampaign.platforms.length) {
        doAlert({ title: `You must select at least 1 platform.`, type: 'error' })
        this.isSaving = false
        return
      }

      try {
        // console.log('this.mode', this.mode)
        const accountClientCampaignsDbRef = new AccountClientCampaignsDB(this.account[0].id, this.$route.params.clientId)
        dbCampaign = await accountClientCampaignsDbRef[this.mode](this.campaign)
        this.isSaving = false
      } catch (error) {
        doAlert({ title: error, type: 'error' })
        this.isSaving = false
        return
      }

      // console.log('this.mode', this.mode)
      // doAlert({
      //   title: `Campagne ${this.mode}d succesfully`,
      //   type: 'success'
      // })

      this.campaign = {
        name: '',
        platforms: [],
      }

      if (this.mode === 'create') {
        this.$router.push({
          name: 'Campaign',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: dbCampaign.id,
          },
        })
      } else {
        this.$router.push({
          name: 'Campaign',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: dbCampaign,
          },
        })
      }
    },
    browserBack() {
      return this.$router.go(-1)
    },
    ...mapActions('users', ['selectClient']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';

.inner-page-header {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 35px;
}

.small-outline-bt {
  position: absolute;
  top: 90px;
  left: 30px;
}

.create-campaign-form {
  width: 100%;
  max-width: 765px;
  margin: 0 auto;
  text-align: center;

  .input-label-wrap {
    display: inline-block;
    margin-bottom: 10px;
  }

  input[type='text'] {
    width: 450px;
    display: inline-block;
  }

  p {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 35px;
  }

  .bt {
    margin-top: 35px;
    display: inline-block;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
    }
  }
}

.checkbox-wrap {
  width: 450px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;

  .checkbox {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(50% - 5px);
    height: 110px;
    cursor: pointer;
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid #e0e2e6;
    transition: all 0.2s ease-in-out;

    &.coming-soon {
      opacity: 0.2;
      cursor: default;

      &:hover {
        background-color: transparent;
        border: 1px solid transparent;
      }
    }

    &:hover {
      background-color: #fff;
      border: 1px solid #46c1c0;
    }

    &.active {
      background-color: #fff;
      border: 1px solid #46c1c0;

      .check {
        display: flex;
      }
    }

    p {
      margin: 0;
    }

    .wrap {
      text-align: center;
    }

    img {
      display: inline-block;
      width: auto;
      height: 30px;
      margin-bottom: 10px;
    }

    .check {
      width: 20px;
      height: 20px;
      background-color: #46c1c0;
      position: absolute;
      right: 10px;
      bottom: 10px;
      border-radius: 15px;
      display: none;
      justify-content: center;
      align-items: center;

      img {
        width: 10px;
        margin-bottom: 0;
      }
    }
  }
}

.other-platform {
  width: 100%;
  max-width: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 60px;

  p {
    width: 100%;
    text-align: center;
    color: $dark-grey;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  img {
    width: 25px;
  }
}
</style>
